import React from 'react';
import { graphql } from 'gatsby';

import Hero from 'components/hero';
import DefaultLayout from 'layouts/default';

const title = 'This page doesn’t exist.';

const ErrorPage = ({ data }) => (
  <DefaultLayout title={title} url="/404">
    <Hero image={data.house.childImageSharp.fluid} title={title} />
  </DefaultLayout>
);

export default ErrorPage;

export const pageQuery = graphql`
  query ErrorQuery {
    house: file(relativePath: { eq: "house.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
